import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { getItem, removeItem, setItem } from '@sitemule/core/utils/localStorage';
import { Request } from 'express';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { REQUEST } from 'src/express.tokens';
import { getWindow } from 'ssr-window';

@Injectable()
export class HttpApiInterceptor implements HttpInterceptor {
  constructor(@Optional() @Inject(REQUEST) private httpRequest: Request) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestUrl = req.url;

    if (requestUrl.startsWith('@api/')) {
      requestUrl = requestUrl.replace('@api', environment.apiDomain);
    }
    const cookie = this.httpRequest?.headers.cookie;

    if (cookie) {
      return next.handle(
        req.clone({
          url: requestUrl,
          withCredentials: true,
          setHeaders: {
            Cookie: cookie,
          },
        })
      );
    }
    return next
      .handle(
        req.clone({
          url: requestUrl,
          withCredentials: true,
        })
      )
      .pipe(
        catchError(err => {
          if (!getItem('login-reload') && (err.status === 401 || err.status === 403)) {
            setItem('login-reload', 'true');
            getWindow().location.reload();
          }
          return throwError(() => err);
        }),
        tap(res => {
          if (requestUrl.endsWith(`/login`) && res.type === HttpEventType.Response && res.body.success) {
            removeItem('login-reload');
          }
        })
      );
  }
}
