import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { SharedErrorHandler } from '@sitemule/core';

// const enableSentry = !isDevMode();
const enableSentry = true; // Always enable for now

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler extends SharedErrorHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public override handleError(error: any) {
    if (enableSentry) {
      Sentry.captureException(error.originalError || error);
    }

    super.handleError(error);
  }
}
