import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import da from '@angular/common/locales/da';
import {
  BrowserModule,
  HammerModule,
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SITE_SETTINGS_SERVICE_TOKEN } from '@sitemule/core/abstract-services/site-settings.service';
import { ToastModule } from '@sitemule/ng-components/components/toast';
import { AddAcceptLanguageHttpInterceptor } from '@sitemule/ng-components/http-interceptors/add-accept-language';
import { HttpErrorInterceptor as BaseHttpErrorInterceptor } from '@sitemule/ng-components/http-interceptors/log-error';
import { RetryHttpInterceptor } from '@sitemule/ng-components/http-interceptors/retry-http';
import { forkJoin, switchMap } from 'rxjs';
import { routes } from './app.routes';
import { DialogModule } from './components/dialog/dialog.module';
import { AppErrorHandler } from './error-handlers/app.error-handler';
import { HttpApiInterceptor } from './intercepters/http-api-intercepter.service';
import { SystemLinkPipe } from './pipes/system-link-pipe.pipe';
import { MenuService } from './services/menu.service';
import { UserService } from './services/sitemule-user.service';
import { SiteSettingsService } from './services/siteSettings.service';
import { DefaultTranslateConfig } from './utilities/translate.utility';

registerLocaleData(da);

// Set languages and defaultLang on AppInit
export function appInitializerFactory(
  siteSettingsService: SiteSettingsService,
  translate: TranslateService,
  userService: UserService,
  menuService: MenuService
) {
  return () => {
    return siteSettingsService.fetchSettings().pipe(
      switchMap(settings => {
        translate.addLangs(settings.available_languages);
        translate.setDefaultLang(settings.default_language);
        return forkJoin({
          translate: translate.use(settings.default_language),
          userData: userService.load(),
          primaryMenu: menuService.getMenuById(settings.primary_navigation_id),
        });
      })
    );
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
        filter: req => {
          if (req.method.toLowerCase() === 'get' && req.url.includes('/cms/ord/')) {
            return false;
          }
          if (req.method.toLowerCase() === 'post' && req.url.includes('/cms/prd/')) {
            return false;
          }
          if (req.method.toLowerCase() === 'get' && req.url.includes('getCurrentClient')) {
            return false;
          }
          return true;
        },
      })
    ),
    importProvidersFrom([
      BrowserModule,
      TranslateModule.forRoot(DefaultTranslateConfig),
      ToastModule,
      DialogModule,
      HammerModule,
    ]),
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [SiteSettingsService, TranslateService, UserService, MenuService],
      multi: true,
    },
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
      })
    ),
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAcceptLanguageHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseHttpErrorInterceptor,
      multi: true,
    },
    {
      provide: SITE_SETTINGS_SERVICE_TOKEN,
      useClass: SiteSettingsService,
    },
    CurrencyPipe,
    SystemLinkPipe,
  ],
};
