import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isFullPath } from '@sitemule/ng-components/directives/navigation';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

const includesLanguageInUrl = (url: string, available_languages: string[]): boolean => {
  if (new RegExp(`^/(${available_languages.join('|')})$`).test(url)) {
    return true;
  }
  return new RegExp(`^/(${available_languages.join('|')})/`).test(url);
};

// Adds language in url if needed
export const addLanguageKeyInUrl = (url: string, language: string, available_languages: string[]): string => {
  if (!url) {
    return `/${language}`;
  }

  if (isFullPath(url)) {
    return url;
  }
  const urlThatStartsWithSlash = url.startsWith('/') ? url : `/${url}`;

  if (includesLanguageInUrl(urlThatStartsWithSlash, available_languages)) {
    return urlThatStartsWithSlash;
  }

  return `/${language}${urlThatStartsWithSlash}`;
};

// Navigation service object to url
const getURL = (
  item: MenuItemResponse
):
  | {
      label: string;
      url: string;
    }
  | undefined => {
  if (item.fcid === 'link') {
    return {
      label: item.title,
      url: item.url,
    };
  }
  if (item.fcid === 'item' && 'page' in item) {
    return {
      label: item.title || item.page.header,
      url: item.page.seourl,
    };
  }
  if (item.fcid === 'item' && 'product' in item) {
    return {
      label: item.title || item.product.header,
      url: item.product.seourl,
    };
  }
  if (item.fcid === 'item' && 'catalog' in item) {
    return {
      label: item.title || item.catalog.header,
      url: item.catalog.seourl,
    };
  }
  return undefined;
};

const mapToMenuItem = (items: MenuItemResponse[], language: string, available_languages: string[]): MenuItem[] => {
  return items.map(item => {
    if (item.fcid === 'folder') {
      const url: [string, boolean | undefined] | undefined = (() => {
        const useLinkFromFirstItem =
          item.fcid === 'folder' &&
          item.items &&
          item.items.length &&
          item.items[0].title?.trim().toLowerCase() === item.title?.trim().toLowerCase();
        if (useLinkFromFirstItem) {
          const firstItem = item.items?.[0];
          if (!firstItem) {
            return undefined;
          }
          const url = getURL(firstItem);
          if (url) {
            return [url.url, 'new_window' in firstItem ? firstItem.new_window : undefined];
          }
        }
        return undefined;
      })();

      return {
        title: item.title,
        url: url ? addLanguageKeyInUrl(url[0], language, available_languages) : undefined,
        target: url?.[1] ? 'blank' : undefined,
        items: mapToMenuItem(url ? [...(item.items || [])].splice(1) : item.items || [], language, available_languages),
      };
    }
    const url = getURL(item);
    return {
      title: url?.label || '',
      url: addLanguageKeyInUrl(url?.url || '', language, available_languages),
      target: item.new_window ? 'blank' : undefined,
    };
  });
};

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(
    public http: HttpClient,
    private translate: TranslateService
  ) {}

  public getMenuResponseById(id: string) {
    return this.http.get<IListMenuResponse[]>(`@api/cms/cms/listMenus/${id}`);
  }

  public getMenuById(id: string): Observable<MenuItem[]> {
    return this.getMenuResponseById(id).pipe(
      map(s => mapToMenuItem(s[0].items || [], this.translate.currentLang, this.translate.getLangs())),
      shareReplay(1)
    );
  }
}

export interface MenuItem {
  title: string;
  url?: string;
  target?: 'blank';
  items?: MenuItem[];
}

interface MenuItemResponseLink {
  title: string;
  new_window?: boolean;
  fcid: 'link';
  url: string;
}
type MenuItemResponseItem =
  | {
      fcid: 'item';
      title: string;
      new_window?: boolean;
      page: {
        seourl: string;
        header: string;
      };
    }
  | {
      fcid: 'item';
      title: string;
      new_window?: boolean;
      product: {
        seourl: string;
        header: string;
      };
    }
  | {
      fcid: 'item';
      title: string;
      new_window?: boolean;
      catalog: {
        seourl: string;
        header: string;
      };
    };
interface MenuItemResponseFolder {
  title: string;
  fcid: 'folder';
  items?: MenuItemResponse[];
}

type MenuItemResponse = MenuItemResponseLink | MenuItemResponseItem | MenuItemResponseFolder;

type IListMenuResponse = MenuItemResponseFolder;
