/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable, Subject } from 'rxjs';

export class DialogRef {
  public close(result?: any) {
    this._onClose.next(result);

    setTimeout(() => {
      this._onClose.complete();
    }, 1000);
  }

  public destroy() {
    this._onDestroy.next(null);
  }

  private readonly _onClose = new Subject<any>();
  public onClose: Observable<any> = this._onClose.asObservable();

  private readonly _onDestroy = new Subject<any>();
  public onDestroy: Observable<any> = this._onDestroy.asObservable();
}
