import { HttpClient } from '@angular/common/http';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

class CustomTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  public getTranslation(lang: string): Observable<object> {
    return of<object>({
      Home: 'Forside',
    });
    return this.http.get<object>(`@api/mnu/txt/listTextTranslation/cms/${lang}`);
  }
}

export class MissingTranslationHelper implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams) {
    const key = params.key;

    console.warn(`Translation not found for key: ${key}`);
    return key;
  }
}

export const DefaultTranslateConfig = {
  missingTranslationHandler: {
    provide: MissingTranslationHandler,
    useClass: MissingTranslationHelper,
  },
  loader: {
    provide: TranslateLoader,
    useClass: CustomTranslateHttpLoader,
    deps: [HttpClient],
  },
};
