import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SiteSettingsService as AbstractSiteSettingsService } from '@sitemule/core/abstract-services/site-settings.service';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

interface AppSettings {
  default_language: string;
  available_languages: string[];
  landingPageKey: string;
  notFoundPageKey: string;
  secondary_navigation_id: string;
  primary_navigation_id: string;
  contactPhone: string;
  contactEmail: string;
}

@Injectable({
  providedIn: 'root',
})
export class SiteSettingsService extends AbstractSiteSettingsService {
  public override currency$ = new BehaviorSubject('DKK');
  public override locale$ = new BehaviorSubject('da');
  public override siteName$ = new BehaviorSubject('');
  public readonly activeUrl = signal(this.route.url);

  public readonly http = inject(HttpClient);
  public readonly settings = signal<AppSettings | undefined>(undefined);
  constructor(private route: Router) {
    route.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.activeUrl.set(e.urlAfterRedirects);
      }
    });
    super();
  }

  private getPageKeyFromPageId(id: number): Observable<string | undefined> {
    return this.http.get<{ type: string; seourl: string }>(`@api/cms/pag/getPage/${id}`).pipe(
      catchError(err => {
        return of(undefined); // Return undefined of error from backend
      }),
      map(pageItem => {
        if (!pageItem) {
          return undefined;
        }
        return pageItem.seourl;
      })
    );
  }
  public fetchSettings(): Observable<AppSettings> {
    return of(undefined).pipe(
      map(() => {
        const settings = {
          default_language: 'da',
          available_languages: ['da', 'en'],
          landingPageKey: 'home',
          notFoundPageKey: '404',
          secondary_navigation_id: environment.primaryNavigationId,
          primary_navigation_id: environment.primaryNavigationId,
          contactPhone: '+45 8642 8511',
          contactEmail: 'ordre@eriksen-randers.dk',
        };
        this.settings.set(settings);
        return settings;
      })
    );
    // return forkJoin([
    //   this.http
    //     .get<{
    //       properties: {
    //         not_found_404_page_id: number;
    //         landing_page_id: number;
    //         mail_replyEmail: string;
    //         folder_corpnavigation_token: number;
    //         folder_navigation_token: number;
    //       };
    //       system: {
    //         default_language: string;
    //         data_languages: string[];
    //       };
    //     }>(`@api/cms/cms/getCurrentSystemConfig`)
    //     .pipe(
    //       map(res => {
    //         const {
    //           not_found_404_page_id,
    //           landing_page_id,
    //           mail_replyEmail,
    //           folder_corpnavigation_token,
    //           folder_navigation_token,
    //         } = res.properties;
    //         const { default_language, data_languages } = res.system;

    //         const not_found_page_id = (() => {
    //           if (not_found_404_page_id) {
    //             return not_found_404_page_id;
    //           }

    //           throw new Error(`404 page is not configured in site settings.`);
    //         })();

    //         if (!landing_page_id) {
    //           throw new Error(`Landing page is not configured in site settings.`);
    //         }

    //         if (!data_languages || !Array.isArray(data_languages) || !data_languages.length) {
    //           throw new Error(`Available languages are not configured in site settings.`);
    //         }

    //         if (!default_language) {
    //           throw new Error(`Default language is not configured in site settings.`);
    //         }

    //         if (!data_languages.includes(default_language)) {
    //           throw new Error(`Default language is not included in available languages.`);
    //         }
    //         return {
    //           not_found_404_page_id: not_found_page_id,
    //           landing_page_id,
    //           mail_replyEmail,
    //           default_language,
    //           available_languages: data_languages,
    //           secondary_navigation_id: folder_corpnavigation_token,
    //           primary_navigation_id: folder_navigation_token,
    //           contactPhone: '',
    //         };
    //       })
    //     ),
    // ]).pipe(
    //   switchMap(([data]) => {
    //     return forkJoin([
    //       this.getPageKeyFromPageId(data.landing_page_id),
    //       this.getPageKeyFromPageId(data.not_found_404_page_id),
    //     ]).pipe(
    //       map(([landing_page_key, not_found_404_page_key]) => {
    //         if (!landing_page_key) {
    //           throw new Error(`Page id '${data.landing_page_id}' is not valid`);
    //         }
    //         if (!not_found_404_page_key) {
    //           throw new Error(`Page id '${data.landing_page_id}' is not valid`);
    //         }
    //         return {
    //           ...data,
    //           landing_page_key,
    //           not_found_404_page_key,
    //         };
    //       })
    //     );
    //   }),
    //   map(
    //     ({
    //       mail_replyEmail,
    //       default_language,
    //       available_languages,
    //       secondary_navigation_id,
    //       primary_navigation_id,
    //       landing_page_key,
    //       not_found_404_page_key,
    //     }) => {
    //       const settings = {
    //         default_language,
    //         available_languages,
    //         landingPageKey: landing_page_key,
    //         notFoundPageKey: not_found_404_page_key,
    //         rootCatalogKey: 'fdfd', // TODO we need this
    //         email: mail_replyEmail,
    //         secondary_navigation_id: `${secondary_navigation_id}`,
    //         primary_navigation_id: `${primary_navigation_id}`,
    //       };
    //       this.settings.set(settings);
    //       return settings;
    //     }
    //   )
    // );
  }
}
