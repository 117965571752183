import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const systemLinkConfig = [
  {
    language: 'en',
    basket: 'basket',
    address: 'address',
    user: 'user',
    profile: 'profile',
    login: 'login',
    search: 'search',
    'forgot-password': 'forgot-password',
    favorites: 'favorites',
    'embed-dashboard': 'embed-dashboard',
    orders: 'orders',
    create: 'create',
    logout: 'logout',
  },
  {
    language: 'da',
    basket: 'kurv',
    address: 'address',
    user: 'bruger',
    profile: 'profil',
    login: 'login',
    search: 'soeg',
    'forgot-password': 'glemt-kodeord',
    favorites: 'favorites',
    'embed-dashboard': 'embed-dashboard',
    orders: 'orders',
    create: 'opret',
    logout: 'log-ud',
  },
  {
    language: 'se',
    basket: 'krog',
    address: 'address',
    user: 'anvandare',
    profile: 'profil',
    login: 'login',
    search: 'sok',
    'forgot-password': 'glomt-losenord',
    favorites: 'favorites',
    'embed-dashboard': 'embed-dashboard',
    orders: 'orders',
    create: 'scapa',
    logout: 'logga-ut',
  },
];

@Pipe({
  name: 'toSystemLink',
  standalone: true,
})
export class SystemLinkPipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);

  public transform(key: string): string {
    const language = this.translateService.currentLang;
    const config = systemLinkConfig.find(c => c.language === language);
    if (!config) {
      throw `System-link-config for language: ${language} not found`;
    }

    if (key === 'home') {
      return `/${language}`;
    }

    if (key === 'basket') {
      return `/${language}/${config.basket}`;
    }

    if (key === 'user') {
      return `/${language}/${config.user}`;
    }

    if (key === 'user-address') {
      return `/${language}/${config.user}/${config.address}`;
    }
    if (key === 'user-login') {
      return `/${language}/${config.user}/${config.login}`;
    }
    if (key === 'user-create') {
      return `/${language}/${config.user}/${config.create}`;
    }
    if (key === 'user-favorites') {
      return `/${language}/${config.user}/${config.favorites}`;
    }
    if (key === 'user-profile') {
      return `/${language}/${config.user}/${config.profile}`;
    }
    if (key === 'user-orders') {
      return `/${language}/${config.user}/${config.orders}`;
    }
    if (key === 'user-forgot-password') {
      return `/${language}/${config.user}/${config['forgot-password']}`;
    }
    if (key === 'user-logout') {
      return `/${language}/${config.user}/${config.logout}`;
    }
    if (key === 'search') {
      return `/${language}/${config.search}`;
    }

    if (key === 'basket-address') {
      return `/${language}/${config.basket}/${config.address}`;
    }
    if (key === 'embed-dashboard') {
      return `/${language}/${config.user}/${config['embed-dashboard']}`;
    }

    console.warn(`System-link-config for language: ${language} and key: ${key} not found`);
    return `/${language}/${key}`;
  }
}
