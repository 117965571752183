/* eslint-disable @typescript-eslint/no-explicit-any */
import { animate, animation, AnimationEvent, style, transition, trigger, useAnimation } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  PLATFORM_ID,
  Renderer2,
  Type,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { DialogConfig } from './dialog-config';
import { DialogRef } from './dialog-ref';
import { DialogContentDirective } from './dialogcontent.directive';

const showModal = animation([
  style({ transform: '{{transform}}', opacity: 0 }),
  animate('150ms cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'none', opacity: 1 })),
]);

const hideModal = animation([
  animate('150ms cubic-bezier(0, 0, 0.2, 1)', style({ transform: '{{transform}}', opacity: 0 })),
]);

@Component({
  selector: 'app-dialog',
  template: `
    <div
      #mask
      style="position:fixed;inset:0;z-index:1000;display:flex;background-color:rgba(0,0,0,.4);"
      [style]="
        config.position === 'right'
          ? 'justify-content:right;'
          : config.position === 'left'
            ? 'justify-content:left;'
            : 'align-items:center;justify-content:center;'
      "
      [ngStyle]="config.maskStyle"
      [ngClass]="config.maskStyleClass">
      <div
        style="overflow:auto;"
        [style]="
          config.position === 'right' || config.position === 'left' ? 'height:100%;' : 'max-width:90vw;max-height:90vh;'
        "
        [ngStyle]="config.style"
        [ngClass]="config.styleClass"
        [@animation]="{
          value: 'visible',
          params: {
            transform:
              config.position === 'right'
                ? 'translate(100%, 0)'
                : config.position === 'left'
                  ? 'translate(-100%, 0)'
                  : 'scale(0.7)'
          }
        }"
        (@animation.start)="onAnimationStart($event)"
        (@animation.done)="onAnimationEnd($event)"
        role="dialog"
        *ngIf="visible"
        [attr.aria-modal]="true">
        <ng-template appDialogContent></ng-template>
      </div>
    </div>
  `,
  animations: [
    trigger('animation', [
      transition('void => visible', [useAnimation(showModal)]),
      transition('visible => void', [useAnimation(hideModal)]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  public visible = true;

  public componentRef?: ComponentRef<any>;

  public _style: any = {};

  @ViewChild(DialogContentDirective)
  public insertionPoint?: DialogContentDirective;

  @ViewChild('mask') public maskViewChild?: ElementRef;

  public childComponentType?: Type<any>;

  public container?: HTMLDivElement;

  public wrapper?: HTMLElement;

  public documentEscapeListener?: () => void;

  public maskClickListener?: () => void;

  public transformOptions = 'scale(0.7)';

  public get style(): any {
    return this._style;
  }

  public set style(value: any) {
    if (value) {
      this._style = { ...value };
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private cd: ChangeDetectorRef,
    public renderer: Renderer2,
    public config: DialogConfig,
    private dialogRef: DialogRef,
    public zone: NgZone
  ) {}

  public ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType!);
    this.cd.detectChanges();
  }

  public loadChildComponent(componentType: Type<any>) {
    const viewContainerRef = this.insertionPoint?.viewContainerRef;
    viewContainerRef?.clear();

    this.componentRef = viewContainerRef?.createComponent(componentType);
    const inputData = this.config.data;
    if (inputData) {
      Object.keys(inputData).forEach(key => {
        this.componentRef?.setInput(key, inputData[key]);
      });
    }
  }

  public onAnimationStart(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        this.container = event.element;
        this.wrapper = (this.container as HTMLDivElement).parentElement || undefined;
        this.bindGlobalListeners();

        this.enableModality();
        this.focus();
        break;

      case 'void':
        if (this.wrapper) {
          this.wrapper.classList.add('p-component-overlay-leave');
        }
        break;
    }
  }

  public onAnimationEnd(event: AnimationEvent) {
    if (event.toState === 'void') {
      this.onContainerDestroy();
      this.dialogRef.destroy();
    }
  }

  public onContainerDestroy() {
    this.unbindGlobalListeners();
    this.disableModality();
    this.container = undefined;
  }

  public close() {
    this.visible = false;
    this.cd.markForCheck();
  }

  public hide() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private _prevOverflow = '';
  public enableModality() {
    if (this.config.closeOnMaskClick) {
      this.maskClickListener = this.renderer.listen(this.wrapper, 'click', (event: any) => {
        if (this.wrapper && this.wrapper.isSameNode(event.target)) {
          this.hide();
        }
      });
    }
    this._prevOverflow = this.document.body.style.overflow;
    this.document.body.style.overflow = 'hidden';
  }

  public disableModality() {
    if (this.wrapper) {
      this.unbindMaskClickListener();

      this.document.body.style.overflow = this._prevOverflow;

      if (!(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }
    }
  }

  public focus() {
    const autoFocusElement = this.container?.querySelector('[autofocus]') as any;
    if (autoFocusElement) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => autoFocusElement.focus(), 5);
      });

      return;
    }
  }

  public resetPosition() {
    (this.container as HTMLDivElement).style.position = '';
    (this.container as HTMLDivElement).style.left = '';
    (this.container as HTMLDivElement).style.top = '';
    (this.container as HTMLDivElement).style.margin = '';
  }

  public bindGlobalListeners() {
    this.bindDocumentEscapeListener();
  }

  public unbindGlobalListeners() {
    this.unbindDocumentEscapeListener();
  }

  public bindDocumentEscapeListener() {
    if (this.config.closeOnEscape) {
      const documentTarget: any = this.maskViewChild ? this.maskViewChild.nativeElement.ownerDocument : 'document';

      this.documentEscapeListener = this.renderer.listen(documentTarget, 'keydown', event => {
        if (event.which == 27) {
          this.hide();
        }
      });
    }
  }

  public unbindDocumentEscapeListener() {
    if (this.documentEscapeListener) {
      this.documentEscapeListener();
      this.documentEscapeListener = undefined;
    }
  }

  public unbindMaskClickListener() {
    if (this.maskClickListener) {
      this.maskClickListener();
      this.maskClickListener = undefined;
    }
  }

  public ngOnDestroy() {
    this.onContainerDestroy();

    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
