import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  signal,
} from '@angular/core';

@Component({
  selector: 'app-vertical-text-slider',
  standalone: true,
  templateUrl: './vertical-text-slider.component.html',
  styleUrls: ['./vertical-text-slider.component.scss'],
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalTextSliderComponent implements OnDestroy {
  public readonly _texts = signal<string[]>([]);
  @Input({ required: true }) public set texts(t: string[]) {
    this._texts.set(t);
  }

  @Output() public itemClick = new EventEmitter<string>();

  public readonly index = signal(0);
  public readonly animating = signal(true);

  // public readonly _renderedTexts = computed(() => {
  //   const texts = this._texts();
  //   const index = this.index();
  //   const nextIndex = index + 1 === texts.length ? 0 : index + 1;
  //   return [texts[index], texts[nextIndex]];
  // });
  public readonly renderedTexts = computed(() => {
    const texts = this._texts();
    return [...texts, ...texts];
  });

  private readonly eff = effect(() => {
    const texts = this._texts();
    if (this.timeout) {
      this.clearTimeout();
    }
    if (texts.length > 1) {
      this.initiateTimer();
    }
  });

  private timeout?: number;

  public ngOnDestroy(): void {
    this.clearTimeout();
  }

  private initiateTimer() {
    this.timeout = window.setTimeout(() => {
      const texts = this._texts();
      const index = this.index();
      const nextIndex = index + 1;
      this.index.set(nextIndex);
      if (nextIndex === texts.length) {
        setTimeout(() => {
          this.animating.set(false);
          this.index.set(0);
          setTimeout(() => {
            this.animating.set(true);
          }, 300);
        }, 300);
      }
      this.initiateTimer();
    }, 5000);
  }

  public clearTimeout() {
    clearTimeout(this.timeout);
    this.timeout = undefined;
  }

  @HostListener('mouseover')
  public onMouseOver() {
    this.clearTimeout();
  }

  @HostListener('mouseout')
  public onMouseOut() {
    this.initiateTimer();
  }
}
