/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-explicit-any */
export class DialogConfig<T = any> {
  maskStyle?: {
    [klass: string]: any;
  } | null;
  maskStyleClass?: string;
  style?: {
    [klass: string]: any;
  } | null;
  styleClass?: string;
  position?: 'center' | 'right' | 'left' = 'center';
  closeOnEscape?: boolean;
  closeOnMaskClick?: boolean;
  data?: {
    [key: string]: unknown;
  };
}
