import { Component, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, Routes } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SEOService } from '@sitemule/utils/services/seo';
import { switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getWindow } from 'ssr-window';
import { systemLinkConfig } from './pipes/system-link-pipe.pipe';
import { UserService } from './services/sitemule-user.service';

@Component({
  template: ``,
})
export class EmptyComponent {}

export const routes: Routes = [
  {
    path: '',
    canActivate: [
      (_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
        const router = inject(Router);
        const translateService = inject(TranslateService);
        const url = _state.url === '/' ? '' : _state.url;
        return router.navigateByUrl(`/${translateService.defaultLang}${url}`, {
          replaceUrl: true,
        });
      },
    ],
    component: EmptyComponent,
  },
  {
    path: 'setPassword',
    canActivate: [
      (_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
        getWindow().location.replace(`${environment.apiDomain}/${_state.url}`);
      },
    ],
    component: EmptyComponent,
  },
  ...systemLinkConfig.map<Route>(
    ({
      language,
      user,
      profile,
      favorites,
      orders,
      basket,
      address,
      login,
      search,
      create,
      logout,
      'embed-dashboard': embedDashboard,
      'forgot-password': forgotPassword,
    }) => {
      return {
        path: language,
        resolve: [
          () => {
            // Set the proper language before loading page
            const translateService = inject(TranslateService);
            const seoService = inject(SEOService);
            seoService.setPageLanguage(language);
            return translateService.use(language);
          },
        ],
        children: [
          {
            path: '',
            loadComponent: () => import('./views/page/page.component').then(mod => mod.PageComponent),
          },
          {
            path: `${search}`,
            loadComponent: () => import('./views/search/search.component').then(mod => mod.SearchComponent),
          },
          {
            path: `${user}/${logout}`,
            resolve: [
              () => {
                const userService = inject(UserService);
                const router = inject(Router);

                if (userService.isLoggedIn()) {
                  return userService.logout().pipe(
                    switchMap(() => {
                      return router.navigateByUrl('/', {
                        replaceUrl: true,
                      });
                    })
                  );
                }
                return router.navigateByUrl('/', {
                  replaceUrl: true,
                });
              },
            ],
            component: EmptyComponent,
          },
          {
            path: '',
            canActivate: [
              (_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
                const userService = inject(UserService);

                if (!userService.isLoggedIn()) {
                  return true;
                }
                const router = inject(Router);
                return router.navigateByUrl(`/${language}/${user}/${profile}`, {
                  replaceUrl: true,
                });
              },
            ],
            children: [
              {
                path: `${user}/${login}`,
                loadComponent: () =>
                  import('./views/user-login/user-login.component').then(mod => mod.UserLoginComponent),
              },
              {
                path: `${user}/${create}`,
                loadComponent: () =>
                  import('./views/user-create/user-create.component').then(mod => mod.UserCreateComponent),
              },
              {
                path: `${user}/${forgotPassword}`,
                loadComponent: () =>
                  import('./views/user-forgot-password/user-forgot-password.component').then(
                    mod => mod.UserForgotPasswordComponent
                  ),
              },
            ],
          },
          {
            path: '',
            canActivate: [
              (_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
                const userService = inject(UserService);

                if (userService.isLoggedIn()) {
                  return true;
                }
                const router = inject(Router);
                return router.navigateByUrl(
                  `/${language}/${user}/${login}?redirect=${encodeURIComponent(_state.url)}`,
                  {
                    replaceUrl: true,
                  }
                );
              },
            ],
            children: [
              {
                path: basket,
                loadComponent: () => import('./views/basket/basket.component').then(mod => mod.BasketComponent),
              },
              {
                path: `${basket}/${address}`,
                loadComponent: () =>
                  import('./views/basket-address/basket-address.component').then(mod => mod.BasketAddressComponent),
              },
              {
                path: user,
                redirectTo: `${user}/${profile}`,
                pathMatch: 'full',
              },
              {
                path: `${user}/${profile}`,
                loadComponent: () =>
                  import('./views/user-profile/user-profile.component').then(mod => mod.UserProfileComponent),
              },
              {
                path: `${user}/${favorites}`,
                loadComponent: () =>
                  import('./views/user-fav-list/user-fav-list.component').then(mod => mod.UserFavListComponent),
              },
              {
                path: `${user}/${embedDashboard}`,
                loadComponent: () =>
                  import('./views/embed-dashboard/embed-dashboard.component').then(mod => mod.EmbedDashboardComponent),
              },
              {
                path: `${user}/${orders}`,
                loadComponent: () =>
                  import('./views/user-orders/user-orders.component').then(mod => mod.UserOrdersComponent),
              },
            ],
          },
          {
            path: ':key',
            loadComponent: () => import('./views/page/page.component').then(mod => mod.PageComponent),
          },
        ],
      };
    }
  ),
  {
    path: '**',
    loadComponent: () => import('./views/page/page.component').then(mod => mod.PageComponent),
    data: { 404: true },
  },
];
