import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { map, Observable, of, switchMap, tap } from 'rxjs';

const favorite_list = 'favorite_list';

@Injectable({
  providedIn: 'root',
})
export class FavoriteProductsService {
  private readonly http = inject(HttpClient);

  private getFavListId(): Observable<number | undefined> {
    return this.http
      .post<
        {
          list_id: number;
        }[]
      >(`@api/cms/lst/listLists`, {
        search: '',
        listtype: 'favorite_list',
        pageno: 1,
        limit: 1,
      })
      .pipe(map(res => res[0]?.list_id));
  }

  private getFavListENUMId() {
    return this.http
      .post<
        {
          id: number;
          listtype: string;
        }[]
      >(`@api/cms/lst/listListTypes`, { search: '', type: 'list or line' })
      .pipe(
        map(res => {
          const item = res.find(i => i.listtype === favorite_list);
          if (!item) {
            throw `Could not find the ENUM ID for list type: ${favorite_list}`;
          }
          return item.id;
        })
      );
  }

  private createFavList() {
    return this.getFavListENUMId().pipe(
      switchMap(id => {
        return this.http
          .post<{
            list_id: number;
          }>(`@api/cms/lst/upsertList/0`, { description: 'Favorite list', collection_id: id })
          .pipe(map(res => res.list_id));
      })
    );
  }

  private getOrCreateFavListId() {
    return this.getFavListId().pipe(
      switchMap(id => {
        if (!id) {
          return this.createFavList();
        }
        return of(id);
      })
    );
  }

  public addProductToFavList(productId: string) {
    return this.getOrCreateFavListId().pipe(
      switchMap(id => {
        return this.http.post<
          {
            list_line_id: number;
            product: {
              id: number;
              seourl: string;
            };
          }[]
        >(`@api/cms/lst/addRowToList/${id}`, {
          productIds: [productId],
        });
      }),
      tap(res => {
        this.favListItems.set([
          ...(this.favListItems() || []),
          {
            lineId: `${res[0].list_line_id}`,
            product: {
              id: `${res[0].product.id}`,
              seourl: res[0].product.seourl,
            },
          },
        ]);
      })
    );
  }

  public removeLineFromFavList(lineId: string) {
    return this.http
      .post<{ success: boolean }>(`@api/cms/lst/removeListLine`, {
        lineIds: [lineId],
      })
      .pipe(
        tap(() => {
          this.favListItems.set(this.favListItems()?.filter(i => i.lineId !== lineId));
        })
      );
  }

  public favListItems = signal<
    | {
        lineId: string;
        product: {
          id: string;
          seourl: string;
        };
      }[]
    | undefined
  >(undefined);
  public getProductsFromFavList() {
    return this.getOrCreateFavListId().pipe(
      switchMap(id => {
        return this.http
          .post<{
            lines?: {
              list_line_id: number;
              product: {
                id: number;
                seourl: string;
              };
            }[];
          }>(`@api/cms/lst/getList/${id}`, {})
          .pipe(
            map(res => {
              return (res.lines || []).map(line => {
                return {
                  lineId: `${line.list_line_id}`,
                  product: {
                    id: `${line.product.id}`,
                    seourl: line.product.seourl,
                  },
                };
              });
            })
          );
      }),
      tap(res => {
        this.favListItems.set(res);
      })
    );
  }
}
