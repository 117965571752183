import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogComponent } from './dialog.component';
import { DialogService } from './dialog.service';
import { DialogContentDirective } from './dialogcontent.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DialogComponent, DialogContentDirective],
  providers: [DialogService],
  exports: [DialogComponent],
})
export class DialogModule {}
