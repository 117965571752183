/* eslint-disable @typescript-eslint/no-explicit-any */
import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { DialogConfig } from './dialog-config';
import { DialogInjector } from './dialog-injector';
import { DialogRef } from './dialog-ref';
import { DialogComponent } from './dialog.component';

@Injectable()
export class DialogService {
  private readonly dialogComponentRefMap: Map<DialogRef, ComponentRef<DialogComponent>> = new Map();

  constructor(
    private appRef: ApplicationRef,
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public open<T>(
    componentType: Type<T>,
    config?: DialogConfig
  ): {
    dialogRef: DialogRef;
    componentRef: () => ComponentRef<T> | undefined;
  } {
    const dialogRef = this.appendDialogComponentToBody(config || {});

    const dynamicDialogComponentRef = this.dialogComponentRefMap.get(dialogRef);
    if (dynamicDialogComponentRef) {
      dynamicDialogComponentRef.instance.childComponentType = componentType;
    }

    return {
      dialogRef,
      componentRef: () => dynamicDialogComponentRef?.instance.componentRef,
    };
  }

  private appendDialogComponentToBody(config: DialogConfig) {
    const map = new WeakMap();
    map.set(DialogConfig, config);

    const dialogRef = new DialogRef();
    map.set(DialogRef, dialogRef);

    const sub = dialogRef.onClose.subscribe(() => {
      const dialogComponentRef = this.dialogComponentRefMap.get(dialogRef);
      if (dialogComponentRef) {
        dialogComponentRef.instance.close();
      }
    });

    const destroySub = dialogRef.onDestroy.subscribe(() => {
      this.removeDialogComponentFromBody(dialogRef);
      destroySub.unsubscribe();
      sub.unsubscribe();
    });

    const componentRef = createComponent(DialogComponent, {
      environmentInjector: this.appRef.injector,
      elementInjector: new DialogInjector(this.injector, map),
    });

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    this.document.body.appendChild(domElem);

    this.dialogComponentRefMap.set(dialogRef, componentRef);

    return dialogRef;
  }

  private removeDialogComponentFromBody(dialogRef: DialogRef) {
    if (!dialogRef || !this.dialogComponentRefMap.has(dialogRef)) {
      return;
    }

    const dialogComponentRef = this.dialogComponentRefMap.get(dialogRef);
    if (dialogComponentRef) {
      this.appRef.detachView(dialogComponentRef.hostView);
      dialogComponentRef.destroy();
    }
    this.dialogComponentRefMap.delete(dialogRef);
  }
}
